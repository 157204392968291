// jshint esversion: 6

import React from 'react';
import { useLocation } from 'react-router-dom';
import Error from '../Error';
import ProductKeys from './ProductKeys';
import Orders from './Orders';
import { getCustomerById, findCustomer, sendCustomerAccessLink, sendCustomerActivationLink,
         getCustomerProductKeys, getCustomerOrdersById, syncSendOwl, syncLimeLM, lazySync,
         /* mergeCustomer, unmergeCustomer */ } from '../../api';

const Customer = () => {
  const [admin] = React.useState(JSON.parse(localStorage.getItem('admin')));
  const [customer, setCustomer] = React.useState();
  const [customers, setCustomers] = React.useState([]);
  const [productKeys, setProductKeys] = React.useState([]);
  const [orders, setOrders] = React.useState([]);
  const [error, setError] = React.useState();
  const [status, setStatus] = React.useState();
  const [mergeEmail, setMergeEmail] = React.useState({ state: null, email: '', customer: null });
  const [refetchCustomer, setRefetchCustomer] = React.useState(false);

  const useQuery = () => new URLSearchParams(useLocation().search);
  const customerId = useQuery().get('customerId');
  const customerEmail = useQuery().get('customerEmail');

  const syncCustomer = () => {
    // run lazy sync and redirect based on retrieved customer id
    lazySync({ jwt: admin.JWT, email: customerEmail })
      .then(resp => {
        if (resp.Error) {
          setError(resp.Error);
          return;
        }
        if (!resp.CustomerID) {
          setError('Error syncing customer: customer not created');
          return;
        }
        window.location.href=`/admin/customer?customerId=${resp.CustomerID}`;
      });
  };

  const fetchCustomer = () => {
    getCustomerById({ jwt: admin.JWT, customerId })
      .then(resp => {
        if (resp.Error) {
          setError(resp.Error);
          return;
        }
        setCustomer(resp);
      });
  };

  const findCustomers = () => {
    findCustomer({ jwt: admin.JWT, email: mergeEmail.email, orderField: 'email', orderDirection: 'asc' })
      .then(resp => {
        if (resp?.Error) {
          setError(resp.Error);
          return;
        }
        setCustomers(resp.customers);
      });
  };

  const fetchProductKeys = () => {
    getCustomerProductKeys({ jwt: admin.JWT, id: customer.CustomerID })
      .then(resp => {
        if (resp.Error) {
          setError(resp.Error);
          return;
        }
        setProductKeys(resp);
      });
  };

  const fetchOrders = () => {
    getCustomerOrdersById({ jwt: admin.JWT, id: customer.CustomerID })
      .then(resp => {
        if (resp.Error) {
          setError(resp.Error);
          return;
        }
        setOrders(resp);
      });
  };

  React.useEffect(() => {
    if (!customerId) {
      syncCustomer();
      return;
    }
    fetchCustomer();
  }, [refetchCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!customer) return;
    fetchProductKeys();
  }, [customer]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!customer) return;
    fetchOrders();
  }, [customer]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!mergeEmail.email) return;
    findCustomers();
  }, [mergeEmail]); // eslint-disable-line react-hooks/exhaustive-deps

  /*
  const handleClick = () => {
    switch (mergeEmail.state) {
      case 'merge':
        alert("Not implemented yet");
        return;

        // if (!window.confirm(`You are about to merge orders for ${mergeEmail.email} into ${customer.Email}. Are you sure?`)) return;
        // return mergeCustomer({ jwt: admin.JWT, id: customer.CustomerID, customer: mergeEmail.customer })
        //   .then(resp => {
        //     setCustomer(resp);
        //     setMergeEmail({ email: '' });
        //   })
        //   .catch(setError);
      default:
        setMergeEmail({ state: 'search' });
        return;
    }

  };
  */
  const renderSuggestions = () => {
    if (!mergeEmail.state || !mergeEmail?.email?.length) return null;
    const suggestions = [];
    customers.forEach(c => {
      if (c.Email.includes(mergeEmail.email) && c.Email !== customer.Email) {
        suggestions.push(c)
      }
    });
    const list = suggestions.map(customer => <div className='suggestion' key={customer.CustomerID} onClick={() => setMergeEmail({ state: 'merge', email: customer.Email, customer })}>
      {customer.Email}
    </div>);
    return <React.Fragment>{mergeEmail.state === 'search' ? <h5>Select...</h5> : null}{list}</React.Fragment>;
  };

  const handleSync = (e) => {
    setStatus('Processing...');
    switch(e.target.name) {
      case 'sendowl':
        return syncSendOwl({ jwt: admin.JWT, customer })
          .then(resp => {
            setStatus(`Updated ${resp ? resp.length : 0} orders`);
            fetchOrders();
          })
          .catch(setError);
      case 'limelm':
        return syncLimeLM({ jwt: admin.JWT, customer })
          .then(() => {
            setStatus('Updated Product Keys')
            fetchProductKeys();
          })
          .catch(setError);
      default:
        return;
    }
  };

  /*
  const handleDeleteEmail = (email) => {
    alert("Not implemented yet");
    return;

    // if (!window.confirm('Are you sure?')) return null;
    // return unmergeCustomer({ jwt: admin.JWT, id: customer.CustomerID, customer: { Email: email } })
    //   .then(resp => {
    //     setCustomer(customer);
    //   })
    //   .catch(setError)
    //   .finally(() => setStatus('Complete'));
  };
  */
  const handleSendCustomerAccessLink = () => {
    return sendCustomerAccessLink({ jwt: admin.JWT, customer })
      .then(resp => {
        if (resp !== null) {
          setError(resp);
          return;
        }
        setStatus('Access Link Sent');
      });
  };

  const handleSendCustomerActivationLink = ({ activations, orderId, productKey, productId }) => {
    const data = {
      customer: customer,
      activations,
      orderId: parseInt(orderId, 10),
      productId,
      productKey,
    }
    return sendCustomerActivationLink({ jwt: admin.JWT, data })
      .then(resp => {
        if (resp !== null) {
          setError(resp);
          return;
        }
        setStatus('Activation Link Sent');
      });
  };

  const renderAdditionalEmails = () => {
    const emails = customer.AdditionalEmails ? customer.AdditionalEmails.split(',') : [];
    if (emails.length === 0) {
      return <div className='additionalEmail'>None</div> 
    }
    return <table><tbody>{emails.map(email => {
      if (email === '') return null;
      return <tr className='additionalEmail' key={email}>
        <td>{email}</td>
        { /* 
        <td><button className='delete' onClick={() => handleDeleteEmail(email)}>Delete</button></td>
        */ }
      </tr>
    })}</tbody></table>
  };

  if (error && !customer) return <Error error={error} />;
  if (!customer) return null;

  return <div className='customer'>
    { error ? <Error error={error} /> : null}
    <div className='details'>
      <h3>{customer.Email}</h3>
      { status ? <div className='status'>{status}</div> : null }

      <div className='update'>
        <label htmlFor='email'>Additional Emails</label>
        {renderAdditionalEmails()}
        { /*  <button onClick={handleClick}>Merge Emails</button> */ }
        <input hidden={mergeEmail.state !== 'search'} type='text' onChange={(e) => setMergeEmail(mergeEmail => ({ ...mergeEmail, email: e.target.value }))} />
        {renderSuggestions()}
        { /* <button onClick={() => setMergeEmail({ email: '', state: 'search' })} hidden={mergeEmail.state !== 'merge'}>Clear</button> */ }

        <button onClick={handleSync} name='sendowl'>Sync SendOwl Orders</button>
        <button onClick={handleSync} name='limelm'>Sync LimeLM Activations</button>
        <button onClick={handleSendCustomerAccessLink} name='accessLink'>Email License Manager Access Link to Customer</button>
      </div>
    </div>
    <div className='keys'>
      <ProductKeys productKeys={productKeys} admin={admin} setError={setError} fetchProductKeys={fetchProductKeys} refetchCustomer={() => setRefetchCustomer(!refetchCustomer)} handleSendCustomerActivationLink={handleSendCustomerActivationLink} />
    </div>
    <div className='orders'>
      <Orders orders={orders} />
    </div>
  </div>;
};

export default Customer;
