// jshint esversion: 6

import React from 'react';
import { useLocation } from 'react-router-dom';
import Error from '../Error';
import Customers from './Customers';
import { findCustomer, deepSearchCustomer } from '../../api';

const FindCustomers = ({ searchType }) => {
  const [admin] = React.useState(JSON.parse(localStorage.getItem('admin')));
  const [customers, setCustomers] = React.useState([]);
  const [refreshPage, setRefreshPage] = React.useState(false);
  const [sort, setSort] = React.useState({ field: 'customer_id', direction: 'asc' });
  const [error, setError] = React.useState();

  const useQuery = () => new URLSearchParams(useLocation().search);
  const searchTerm = useQuery().get('term');

  const callbackShallow = ({ page, field = 'customer_id', direction }) => {
    if (!page) setRefreshPage(!refreshPage);
    const sortDirection = field === sort.field ? (sort.direction === 'asc' ? 'desc' : 'asc') : 'asc';
    setSort({ field, direction: sortDirection });

    return findCustomer({ jwt: admin.JWT, page, email: searchTerm, orderField: field, orderDirection: sortDirection })
      .then(setCustomers)
      .catch(setError);
  };

  const callbackDeep = () => {
    return deepSearchCustomer({ jwt: admin.JWT, term: searchTerm })
      .then(setCustomers)
      .catch(setError);
  };

  const callback = ({ page, field = 'customer_id', direction }) => {
    if (searchType === 'shallow') {
      callbackShallow({ page, field, direction });
    } else {
      callbackDeep();
    }
  };

  const memoizedCallback = React.useCallback((page, field, direction) => callback({ page, field, direction }), [searchTerm]);

  React.useEffect(() => {
    memoizedCallback({ page: 0, direction: 'asc' });
  }, [searchTerm, memoizedCallback]);

  if (error) return <Error error={error} />;

  return <div className='FindCustomers'>
    { customers ? <Customers customers={customers} callback={callback} refreshPage={refreshPage} handleSort={callback} sort={sort} /> : null }
  </div>;
};

export default FindCustomers;
