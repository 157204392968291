// jshint esversion: 6

import React from 'react';
import { login } from '../../api';
import Error from '../Error';

const Login = () => {
  const [email, setEmail] = React.useState();
  const [success, setSuccess] = React.useState();
  const [error, setError] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const validateEmail = (email) => {
    let regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return email.match(regex);
  };

  const handleChange = (e) => {
    setError(null);
    setSuccess(false);
    setLoading(false);
    setEmail(e.target.value);
  };

  const handleClick = () => {
    if (!validateEmail(email)) {
      setError(`${email} is not a valid email address.`);
      return;
    }
    setLoading(true);
    setError(null);
    return login({ email })
      .then(resp => {
        if (resp?.Email === email) {
          setSuccess(true);
        } else {
          setError('Could not send email. Please contact <a href="mailto:support@cinemagrade.com">mailto:support@cinemagrade.com</a> for assistance.');
        }
        setLoading(false);
      })
      .catch(err => {
        setError('Could not send email. Please contact <a href="mailto:support@cinemagrade.com">mailto:support@cinemagrade.com</a> for assistance.');
        setLoading(false);
      });
  };

  const renderSuccess = () => <div className='success'>Now check your email. Access to your licensing has been sent to the email address you’ve specified. If you’re using Gmail, please also check your Spam folder.</div>;
  const renderError = () => <Error error={error} />;
  const renderLoading = () => <div className='loading'>Processing...</div>;

  return <div className='login'>
    <p>Check</p>
    <h4>Your license</h4>
    <p className='instructions'>Enter email address to retrieve your activation code.</p>
    <p className='instructions'>If you are unable to login, contact <a href="mailto:support@cinemagrade.com?subject=License%20Manager%20Access">support@cinemagrade.com</a> for assistance.</p>
    <div className='lineBreak' />
    <div className='form'>
      <input type='text' onChange={handleChange} placeholder='my@email.com'/>
    </div>
    <button onClick={handleClick}>check</button>
    {success ? renderSuccess() : null}
    {error ? renderError() : null}
    {loading ? renderLoading() : null}
  </div>;
};

export default Login;
