// jshint esversion: 6

import React from 'react';
import { useParams, Redirect, Link } from 'react-router-dom';
import { getCustomer } from '../../api';
import { isWindowsOS } from '../../utils';
import Error from '../Error';
import ProductKeyTable from './ProductKeyTable';

const License = () => {
  const { token } = useParams();
  const [customer, setCustomer] = React.useState();
  const [error, setError] = React.useState();
  const [redirect, setRedirect] = React.useState();
  const [refetch, setRefetch] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const fetchCustomer = () => {
      setError(null);
      setLoading(true);
      getCustomer({ token })
        .then(resp => {
          if (!resp || resp?.Error === 'no records found') {
            setRedirect(<Redirect to='/' />);
            setLoading(false);
            return;
          }
          if (resp?.Error === 'login token expired') {
            setError('Your temporary link has expired. Please enter the email address your product is registered with to get a new link which will be valid for 2 hours.');
            setLoading(false);
            return;
          }
          if (resp?.Error) {
            setError(resp.Error);
            setLoading(false);
            return;
          }
          setLoading(false);
          setCustomer(resp);
        });
    }
    fetchCustomer();
    return () => setCustomer(null);
  }, [token, refetch]);

  if (redirect) {
    return <>{ redirect }</>;
  };

  if (error) {
    return <React.Fragment>
      <Error error={error} />
      <Link to='/'>Login</Link>
    </React.Fragment>;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!customer?.Licenses) {
    return <div>No licenses found. Please contact <a href='mailto:support@cologradingcentral.com?subject=No Licenses Found'>support@cologradingcentral.com</a> for assistance.
      <div className='footerLink'><Link to={`/invoices/${token}`}>Show Invoice List</Link></div>
    </div>;
  }

  return <div className='license'>
    <React.Fragment>
      <p>Licenses for:</p>
      <h4>{customer?.Email}</h4>
      <div>additional emails: {customer?.AdditionalEmails}</div>
      <div className='lineBreak' />
      { isWindowsOS() ? null : <p className='instructions'>Click code below to activate.</p> }
      </React.Fragment>
    { customer ? <ProductKeyTable licenses={customer.Licenses} token={token} refetchCustomer={() => setRefetch(!refetch)}/> :null }
    <div className='footerLink'><Link to={`/invoices/${token}`}>Show Invoice List</Link></div>
  </div>;

};

export default License;
