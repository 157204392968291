// jshint esversion: 6

import React from 'react';
import Error from '../Error';
import Customers from './Customers';
import { listCustomers } from '../../api';

const ListCustomers = () => {
  const [admin] = React.useState(JSON.parse(localStorage.getItem('admin')));
  const [customers, setCustomers] = React.useState([]);
  const [refreshPage, setRefreshPage] = React.useState(false);
  const [sort, setSort] = React.useState({ field: 'email', direction: 'asc' });
  const [error, setError] = React.useState();

  const callback = ({ page, field, direction }) => {
    if (!page) setRefreshPage(!refreshPage);
    const sortDirection = direction ? direction : sort.direction;
    const sortField = field ? field : sort.field;
    setSort(sort => ({ field: sortField, direction: sortDirection }));
    return listCustomers({ jwt: admin.JWT, page, orderField: sortField, orderDirection: sortDirection })
      .then(setCustomers)
      .catch(setError);
  };

  React.useEffect(() => {
    callback({ page: 0, direction: 'asc', field: 'customer_id' });
  }, []);

  if (error) return <Error error={error} />;

  return <div className='ListCustomers'>
    { customers ? <Customers customers={customers} callback={callback} refreshPage={refreshPage} handleSort={callback} sort={sort} /> : null }
  </div>;
};

export default ListCustomers;
