// jshint esversion: 6

import React from 'react';

const PaginationControls = ({ items, totalPages, callback, refreshPage }) => {
  const [page, setPage] = React.useState(0);

  React.useEffect(() => {
    setPage(0);
  }, [refreshPage]);

  const handleClick = (destinationPage) => {
    if (destinationPage < 0 || destinationPage >= totalPages) return;
    setPage(destinationPage);
    callback({ page: destinationPage });
  }

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const start = page - 5 > 0 ? page - 5 : 0;

    if (start > 0) {
      pageNumbers.push(<span key={`page-${0}`}  className='paginationControl' onClick={() => handleClick(0)}>1</span>);
      pageNumbers.push(<span key='dots-beginning'>...</span>);
    }

    for (let i = start; i < start + 10 && i < totalPages; i++) {
      pageNumbers.push(<span key={`page-${i}`} className={page === i ? 'paginationControl bold' : 'paginationControl'} onClick={() => handleClick(i)}>{i + 1}</span>);
    }

    if (start + 10 < totalPages) {
      pageNumbers.push(<span key='dots-end'>...</span>);
      pageNumbers.push(<span key={`page-${totalPages}`}  className='paginationControl' onClick={() => handleClick(totalPages - 1)}>{totalPages}</span>);
    }
    return pageNumbers;
  };

  if (!items) return null;

  return <div className='paginationControls'>
    <span className='paginationControl' onClick={() => handleClick(0)}>{'<<'}</span>
    <span className='paginationControl' onClick={() => handleClick(page - 1)}>{'<'}</span>
    {renderPageNumbers()}
    <span className='paginationControl' onClick={() => handleClick(page + 1)}>{'>'}</span>
    <span className='paginationControl' onClick={() => handleClick(totalPages - 1)}>{'>>'}</span>
  </div>;
};


export default PaginationControls;
