// jshint esversion: 6

import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { authorize } from '../../api';
import Error from '../Error';

const Login = ({ setAdmin }) => {
  const [error, setError] = React.useState();
  const responseGoogle = (resp) => {
    return authorize({ token: resp.credential })
      .then(resp => {
        if (resp?.Error) {
          setError(resp.Error);
          return;
        }
        const admin = JSON.stringify(resp);
        localStorage.setItem('admin', admin);
        setAdmin(resp);
      })
      .catch(setError);
  };

  return (
    <div>
      <div className="Login">
        <GoogleLogin
          text="Login"
          onSuccess={responseGoogle}
          onError={responseGoogle}
          size="medium"
        />
        {error ? <Error error={error} /> : null}
      </div>
    </div>
  );
};

export default Login;
