// jshint esversion: 6

import React from 'react';

const Error = ({ error }) => {
  const msg = typeof(error) === 'string' ? error : JSON.stringify(error);
  return <div className='error' dangerouslySetInnerHTML={{ __html: msg }} />;
};

export default Error;
