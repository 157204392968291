// jshint esversion: 6

import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { getCustomerOrder } from '../../api';
import Error from '../Error';
import '../../css/user.css';

const Invoice = () => {
  const { token } = useParams();
  const { id } = useParams();

  const [order, setOrder] = React.useState();
  const [buyer, setBuyer] = React.useState({ });
  const [error, setError]= React.useState();

  React.useEffect(() => {
    const fetchOrder = () => {
      return getCustomerOrder({ token, id})
        .then(resp => {
          if (resp.message) {
            setError(resp.message);
            return;
          }
          if(resp.Error) {
            setError(resp.message);
          }
          const details = JSON.parse(resp.Details);
          setOrder(details);
        })
        .catch(setError);
    }
    fetchOrder();
  }, [token, id]);

  React.useEffect(() => {
    const storedBuyer = JSON.parse(localStorage.getItem('buyer')) || { name: '', billingAddress: '', billingAddress2: '', vatNumber: '', region: '' };
    setBuyer({
      name: order?.buyer_name ? order.buyer_name : storedBuyer.name,
      billingAddress1: order?.billing_address1 ? order.billing_address1 : storedBuyer.billingAddress1,
      billingAddress2: order?.billing_address2 ? order.billing_address2 : storedBuyer.billingAddress2,
      vatNumber: order?.billing_vat_number ? order.billing_vat_number : storedBuyer.vatNumber,
      region: order?.billing_region ? order.billing_region : storedBuyer.region,
      edit: false
    });
  }, [order]);

  const renderItems = () => {
    if (!order?.cart?.cart_items) return null;
    return order.cart.cart_items.map(item => {
      return <tr key={item.cart_item.product_id}>
        <td className='description'>{item?.cart_item?.full_product_name ? item?.cart_item?.full_product_name : item?.cart_item?.product_name }</td>
        <td className='quantity'>{item?.cart_item?.quantity}</td>
        <td className='price'>${item?.cart_item?.price_at_checkout}</td>
      </tr>
    });
  };

  const handleClick = () => {
    window.print();
  };

  const renderEditBuyer = () => {
    return <>
      <input className='buyer' placeholder='Name' name='name' onChange={(e) => setBuyer(buyer => ({...buyer, name: e.target.value}))} value={buyer.name} />
      <input className='buyer' placeholder='Billing Address 1' name='billingAddress1' onChange={(e) => setBuyer(buyer => ({...buyer, billingAddress1: e.target.value}))}  value={buyer.billingAddress1} />
      <input className='buyer' placeholder='Billing Address 2' name='billingAddress2' onChange={(e) => setBuyer(buyer => ({...buyer, billingAddress2: e.target.value}))}  value={buyer.billingAddress2} />
      <input className='buyer' placeholder='City, State Zip' name='vatNumber' onChange={(e) => setBuyer(buyer => ({...buyer, vatNumber: e.target.value}))}  value={buyer.vatNumber} />
      <input className='buyer' placeholder='Region' name='region' onChange={(e) => setBuyer(buyer => ({...buyer, region: e.target.value}))}  value={buyer.region} />
      <button onClick={() => { localStorage.setItem('buyer', JSON.stringify(buyer)); setBuyer(buyer => ({ ...buyer, edit: false }))}}>Save</button>
    </>;
  };

  if (!order) return <div>No invoices. You may need to <Link to='/'>Log in</Link> again.</div>;

  return <div className='invoice'>
    <p>Invoice for:</p>
    <h4>{order.id}</h4>
    <p className='instructions'>Use print function to export to PDF.</p>

    { error ? <Error error={error} /> : null }

    <div className='row'>
      <div className='invoiceHeader'>
        <h2>Invoice</h2>
        <div>Order #{order.id}</div>
        <div>{order?.cart?.completed_checkout_at ? new Date(order.cart.completed_checkout_at).toLocaleDateString() : null}</div>
      </div>
      <div />
    </div>

    <div className='row'>
      <div className='seller'>
        <h5>Seller</h5>
        <div>Color Grading, LLC</div>
        <div>256 S 8600 E</div>
        <div>HUNTSVILLE, UT 84317</div>
        <div>USA</div>
      </div>
      <div className='buyer'>
        <h5>Buyer <small className='editBuyer' onClick={() => setBuyer(buyer => ({...buyer, edit: !buyer.edit}))}>(edit)</small></h5>
        {buyer.edit ? renderEditBuyer() :
          <><div>{buyer.name}</div>
          <div>{buyer.billingAddress1}</div>
          <div>{buyer.billingAddress2}</div>
          <div>{buyer.vatNumber}</div>
          <div>{buyer.region}</div></>
        }
      </div>
    </div>

    <div className='row'>
      <div className='support'>
        <h5>Support</h5>
        <div>support@cinemagrade.com</div>
      </div>
      <div className='email'>
        <h5>Email</h5>
        <div>{order.buyer_email}</div>
      </div>
    </div>

    <div className='row'>
      <table className='products'>
        <thead>
          <tr>
            <th>Description</th>
            <th>Quantity</th>
            <th>Unit Price</th>
          </tr>
        </thead>
        <tbody>
          {renderItems()}
          <tr>
            <td />
            <td className='bold'>Subtotal</td>
            <td>${order.price_at_checkout ? order.price_at_checkout : "0.00"}</td>
          </tr>
          { order?.discount && order.discount !== '0.00' ? <tr>
            <td />
            <td className=''>Discount</td>
            <td>${order.discount ? order.discount : "0.00"}</td>
          </tr> : null }
          { order?.settled_tax && order.settled_tax !== '0.00' ? <tr>
            <td />
            <td className=''>Tax</td>
            <td>${order.settled_tax ? order.settled_tax : "0.00"}</td>
          </tr> : null }
          <tr>
            <td />
            <td className='bold'>Total</td>
            <td>${order.settled_gross ? order.settled_gross : "0.00"}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <button onClick={handleClick}>Print</button>
    <div className='footerLink'><Link to={`/invoices/${token}`}>Show Invoice List</Link></div>
  </div>;
};

export default Invoice;
