// jshint esversion: 6

import React from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import Error from '../Error';
import { deactivate } from '../../api';
import { isWindowsOS } from '../../utils';
import dl from '../../images/dl.png';
import macbook_pro from '../../images/macbook_pro.svg';
import macbook_air from '../../images/macbook_air.svg';
import macbook from '../../images/macbook.svg';
import mac_mini from '../../images/mac_mini.svg';
import mac_pro from '../../images/mac_pro.svg';
import imac from '../../images/imac.svg';
import win from '../../images/win.svg';
import '../../css/user.css';

const imageMap = {
  "MacBookPro": macbook_pro,
  "MacBookAir": macbook_air,
  "MacBook": macbook,
  "iMac": imac,
  "MacMini": mac_mini,
  "MacPro": mac_pro,
  "Win": win,
};

const Activations = ({ productInfo, details, setError, refetchCustomer }) => {
  const { token } = useParams();
  const productId = productInfo.id;

  const handleDeactivate = (productId, activationId, productKey) => {
    deactivate({ token, productId, productKey, activationId })
      .then(resp => {
        if (resp.Error || resp.message) {
          setError(resp.Error ? resp.Error : resp.message);
          alert("Deactivation failed.\n\nPlease contact support@cinemagrade.com");
          return;
        }
        if (!isWindowsOS()) {
          if (window.confirm(`Deactivation Successful!\n\nDo you want to deactivate your local copy of ${productInfo.name}?`)) {
            window.location.href=`${productInfo.bundleId}://deactivate`;
          }
        }
        refetchCustomer();
      });
  };


  if (!details?.activations?.act) return null;
  return details.activations.act.map(activation => {
    const data = activation.extra_data.split(",");
    let imgKey = data.length > 0 ? data[1] : "";
    if (imgKey.search("Mac") === -1) {
      imgKey = "Win";
    } else {
      const idx = imgKey.search(/[^a-zA-Z]/);
      if (idx !== -1) {
        imgKey = imgKey.substr(0, idx).trimStart();
      }
    }

    return  <div className='activation' activation_id="{act_id}" key={activation.id}>
      { imageMap[imgKey] ? <img src={imageMap[imgKey]} height="54" className="act_type" alt={imgKey} />  : null }
      <div className='activationDetails'>
        <span>{activation.extra_data}</span>
      </div>
      <div>
        <button onClick={() => handleDeactivate(productId, activation.id, details.key)}>Deactivate</button>
      </div>
    </div>
  })
};

const featuresMap = {
  "fcpx": "Final Cut Pro X",
  "macadb": "Adobe Premiere Pro (OS X)",
  "macres": "DaVinci Resolve (OS X)",
  "winadb": "Adobe Premiere Pro (Win)",
  "winres": "DaVinci Resolve (Win)",
  "pro": "Pro",
  "ascend_presets": "ASCEND Presets",
  "film_grain": "Film Grain",
  "film_stock_presets": "Film Stock Presets",
  "cinelook": "Cine Look",
  "asteroid_luts": "Asteroid LUTs",
  "teal_luts": "Teal LUTs",
  "moody_luts": "Moody Dark Tone LUTs",
  "lut_export": "3D LUT Export"
}

const ProductKeyTable = ({ licenses, token, refetchCustomer }) => {
  const [error, setError] = React.useState();
  const [deactivationMode, setDeactivationMode] = React.useState(false);

  const handleCoupon = () => {
    alert('not implemented');
  };

  const handleClick = (productInfo, key) => {
    if (!isWindowsOS() && window.confirm(`Do you want to activate your local copy of ${productInfo.name}?`)) {
      window.location.href=`${productInfo.bundleId}://activate/${key}`;
    }
  };

  const handleRefetchCustomer = () => {
    setDeactivationMode(false);
    refetchCustomer();
  };

  const renderProductKeys = () => {
    return licenses.map(license => {
      if (license.ProductKey.Details === '') return null
      const details = JSON.parse(license.ProductKey.Details);
      const info = license.ProductKey.ProductInfo;
      const features = _.filter(details?.features?.feature || [], f =>  _.keys(featuresMap).includes(f.name));
      let downloadMac = _.filter(features, f => ( f.name.startsWith('mac') || f.name === 'fcpx') ).length > 0;
      let downloadWin = _.filter(features, f => f.name.startsWith('win')).length > 0;

      if (!downloadMac && !downloadWin) {
        downloadMac = true;
        downloadWin = true;
      }

      return <tr key={details.key} className='productKey'>
        <td className='features'>
          <div className='productName'>{info.name}</div>
          <ul className='features'>{features.map(feature => feature.value !== '1' ? null : <li key={featuresMap[feature.name]}>{featuresMap[feature.name]}</li>)}</ul>
        </td>
        <td className='download'>
          <ul className='downloads'>
          { downloadMac ? <li><a href={ `https://fd.cinemagrade.com/installer/${info.id}/mac` } ><img src={dl} alt='download' className='download' /><br />download Mac</a></li> : null}
          { downloadWin ? <li><a href={ `https://fd.cinemagrade.com/installer/${info.id}/win` } ><img src={dl} alt='download' className='download' /><br />download Win</a></li> : null}
          </ul>
        </td>
        <td className='version'>{info.version}</td>
        <td className='productKey'>
          <div className="productKeyProper" onClick={() => handleClick(info, details.key)}>{details.key}</div>
          {deactivationMode === details.key ? <Activations productInfo={info} details={details} setError={setError} refetchCustomer={handleRefetchCustomer} /> : null}
        </td>
        <td className='activations'><div>activations:</div>{details.acts ? `${details.acts_used} of ${details.acts}` : 'None'}</td>
        <td className='coupons'>
          <button onClick={() => setDeactivationMode(details.key)} disabled={deactivationMode === details.key ? 'disabled' : false}>Deactivate</button>
          <button onClick={handleCoupon} hidden={true}>Coupon</button>
        </td>
      </tr>
    });
  };

  if (!licenses) return null;

  return <div className='productKey'>
    {error ? <Error error={error} /> : null}
    <table className='productKey'>
      <tbody>
        {renderProductKeys()}
      </tbody>
    </table>
  </div>;
};

export default ProductKeyTable;
