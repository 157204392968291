// jshint esversion: 6

import React from 'react';
import { Link, useParams, Redirect } from 'react-router-dom';
import { getCustomerOrders } from '../../api';
import Error from '../Error';
import '../../css/user.css';

const Invoices = () => {
  const { token } = useParams();
  const [orders, setOrders] = React.useState([]);
  const [redirect, setRedirect] = React.useState();
  const [error, setError] = React.useState();
  const [loading, setLoading] = React.useState(false);

  React.useState(() => {
    const fetchOrders = () => {
      return getCustomerOrders({ token })
        .then(resp => {
          if (!resp || resp?.Error === 'no records found') {
            setRedirect(<Redirect to='/' />);
            setLoading(false);
            return;
          }
          if (resp?.Error === 'login token expired') {
            setError('Your temporary link has expired. Please enter the email address your product is registered with to get a new link which will be valid for 2 hours.');
            setLoading(false);
            return;
          }
          if (resp?.Error) {
            setError(resp.Error);
            setLoading(false);
            return;
          }
          if (resp.message) {
            setError(resp.message);
            return;
          }
          setOrders(resp);
        })
        .catch(setError);
    };
    fetchOrders();
  }, [token]);


  const renderOrders = () => {
    return orders.map(order => {
      if (!order.Details) {
        setError(`Order ${order.OrderID} is missing details.`); // should only occur when using malformed test data
        return null;
      }

      const details = JSON.parse(order.Details);
      if (!details.id) return null;
      const items = details.cart?.cart_items ? details.cart?.cart_items.map((item, i) => <div className='item' key={`item-${i}`}>{item?.cart_item?.product_name}</div>) : null;
      return <tr key={details.id} className='order'>
          <td>{items}</td>
          <td>{details.cart?.completed_checkout_at ? new Date(details.cart.completed_checkout_at).toLocaleDateString() : null}</td>
          <td className='bold'>Order #{details.id}</td>
          <td>${details?.settled_gross ? details?.settled_gross : "0.00"}</td>
          <td><Link to={`/invoice/${details.id}/${token}`}><button>Show Invoice</button></Link></td>
        </tr>;
    });
  };

  if (redirect) {
    return <>{ redirect }</>;
  };

  if (error) {
    return <React.Fragment>
      <Error error={error} />
      <Link to='/'>Login</Link>
    </React.Fragment>;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!orders.length) return <div>No invoices. You may need to <Link to='/'>Log in</Link> again.
    <div className='footerLink'><Link to={`/license/${token}`}>Show License List</Link></div>
  </div>;

  return <div className='invoices'>
    <p>Invoices:</p>
    { error ? <Error error={error} /> : null }
    <table className='orders'>
      <thead />
        <tbody>
          { orders ? renderOrders() : null }
        </tbody>
      </table>
    <div className='footerLink'><Link to={`/license/${token}`}>Show License Keys</Link></div>
  </div>;
};

export default Invoices;
