// jshint esversion: 6

import React from 'react';
import { googleLogout } from '@react-oauth/google';

const logout = () => {
  googleLogout();
  localStorage.removeItem('admin');
};

const Logout = ({ setAdmin }) => {
  const handleClick = () => {
    logout();
    setAdmin();
  };
  return (
    <button className='logout' onClick={handleClick}>
      Logout
    </button>
  );
};

export {
   Logout,
   logout
};
