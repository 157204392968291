// jshint esversion: 6

import React from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Logout } from './Logout';
import Login from './Login';
import logo from '../../images/logo.png';

const Header = ({ child }) => {
  const [admin, setAdmin] = React.useState(JSON.parse(localStorage.getItem('admin')));
  const [term, setTerm] = React.useState('');
  const handleChange = (e) => {
    setTerm(e.target.value);
  };

  if (!admin) {
    return <Login setAdmin={setAdmin} />;
  }

  return <div className='header'>
    <div><img src={logo} alt='cinemagrade' className='headerLogo' /></div>
    <Logout setAdmin={setAdmin} />
    <div className='menu'>
      <div className='item'>
        <Link to='/admin/customers'><button name='list'>List Customers</button></Link>
      </div>
      <div className='item'>
        <input type='text' name='search' onChange={handleChange} placeholder='search...' value={term} />
        <div className='searchButtons'>
          <Link to={`/admin/search?term=${term}`}>
            <button data-tip='searches database for email' name='search'>Search</button>
            <ReactTooltip />
          </Link>
          <Link to={`/admin/deepsearch?term=${term}`}>
            <button data-tip='searches LimeLM and SendOwl for matching email/customer name/order' name='search'>Deep Search</button>
            <ReactTooltip />
          </Link>
        </div>
      </div>
    </div>
    {child}
  </div>;
};

export default Header;
