// jshint esversion: 6

import React from 'react';
import logo from '../../images/logo.png';

const User = ({ child }) => {
  return <div className='user'>
    <div><img src={logo} alt='cinemagrade' className='headerLogo' /></div>
    {child}
  </div>;

};

export default User;
