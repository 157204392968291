// jshint esversion: 6

import { apiUrl } from './config';

const processResp = (resp) => {
  switch (resp.status) {
    case 200:
      return resp.json();
    case 401:
      return { Error: 'login token expired' };
    case 404:
      return { Error: 'no records found' };
    default:
      return resp.json();
  }
};

/* customer */

// customer login
const login = ({ email }) => {
  const formData = new FormData();
  formData.append('email', email);
  const url = `${apiUrl}/login`;
  return fetch(url, {
    method: 'POST',
    body: formData
  }).then(processResp);
};

const getCustomer = ({ token }) => {
  const url = `${apiUrl}/customer/${token}`;
  return fetch(url, {
    method: 'GET'
  }).then(processResp);
};

const getCustomerOrders = ({ token }) => {
  const url = `${apiUrl}/customer/orders/${token}`;
  return fetch(url, {
    method: 'GET'
  }).then(processResp);
};

const getCustomerOrder = ({ token, id }) => {
  const url = `${apiUrl}/customer/order/${id}/${token}`;
  return fetch(url, {
    method: 'GET'
  }).then(processResp);
};

const deactivate = ({ token, productKey, activationId }) => {
  const url = `${apiUrl}/customer/lm/key/${productKey}/activation/${activationId}/${token}`;
  return fetch(url, {
    method: 'DELETE'
  }).then(processResp);
};

/* admin */

// admin login
const authorize = ({ token }) => {
  const url = `${apiUrl}/auth`;
  return fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }).then(resp => resp.json());
};

const getCustomerById = ({ jwt, customerId }) => {
  const url = `${apiUrl}/admin/customer/${customerId}`;
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`
    }
  }).then(resp => resp.json());
};

const listCustomers = ({ jwt, page, orderField, orderDirection }) => {
  const url = page ? `${apiUrl}/admin/customers/list?orderField=${orderField}&orderDirection=${orderDirection}&page=${page}` : `${apiUrl}/admin/customers/list?orderField=${orderField}&orderDirection=${orderDirection}`;
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`
    }
  }).then(resp => resp.json());
};

const findCustomer = ({ jwt, email, page, orderField, orderDirection }) => {
  const url = page ? `${apiUrl}/admin/customers/search?orderField=${orderField}&orderDirection=${orderDirection}&email=${email}&page=${page}` : `${apiUrl}/admin/customers/search?orderField=${orderField}&orderDirection=${orderDirection}&email=${email}`;
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`
    }
  }).then(resp => resp.json());
};

const deepSearchCustomer = ({ jwt, term }) => {
  const url = `${apiUrl}/admin/customers/deepsearch?term=${term}`;
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`
    }
  }).then(resp => resp.json());
};

const lazySync = ({ jwt, email }) => {
  const url = `${apiUrl}/admin/customers/lazysync?email=${email}`;
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`
    }
  }).then(resp => resp.json());
};

const sendCustomerAccessLink = ({ jwt, customer }) => {
  const url = `${apiUrl}/admin/customer/accessLink`;
  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(customer)
  }).then(resp => resp.json());
};

const sendCustomerActivationLink = ({ jwt, data }) => {
  const url = `${apiUrl}/admin/customer/activationLink`;
  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then(resp => resp.json());
};

const getCustomerProductKeys = ({ jwt, id }) => {
  const url = `${apiUrl}/admin/customer/${id}/productKeys`;
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`
    }
  }).then(resp => resp.json());
};

const getCustomerOrdersById = ({ jwt, id }) => {
  const url = `${apiUrl}/admin/customer/${id}/orders`;
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`
    }
  }).then(resp => resp.json());
};

const mergeCustomer = ({ jwt, id, customer }) => {
  const url = `${apiUrl}/admin/customer/${id}/merge`;
  return fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(customer)
  }).then(resp => resp.json());
};

const unmergeCustomer = ({ jwt, id, customer }) => {
  const url = `${apiUrl}/admin/customer/${id}/unmerge`;
  return fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(customer)
  }).then(resp => resp.json());
};

const syncSendOwl = ({ jwt, customer }) => {
  const url = `${apiUrl}/admin/sync/sendowl`;
  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(customer)
  }).then(resp => resp.json());
};

const syncLimeLM = ({ jwt, customer }) => {
  const url = `${apiUrl}/admin/sync/lm`;
  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(customer)
  }).then(resp => resp.json());
};

const revokeLimeLMKey = ({ jwt, productId, productKey }) => {
  const url = `${apiUrl}/admin/lm/product/${productId}/key/${productKey}`;
  return fetch(url, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json'
    }
  }).then(resp => resp.json());
};

const unrevokeLimeLMKey = ({ jwt, productId, productKey }) => {
  const url = `${apiUrl}/admin/lm/product/${productId}/key/${productKey}`;
  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json'
    }
  }).then(resp => resp.json());
};

const adminDeactivate = ({ jwt, productId, productKey, activationId }) => {
  const url = `${apiUrl}/admin/lm/product/${productId}/key/${productKey}/activation/${activationId}`;
  return fetch(url, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json'
    }
  }).then(resp => resp.json());
};

/* util */

const health = () => {
  const url = `${apiUrl}/health`;
  return fetch(url, {
    method: 'GET'
  }).then(resp => resp.text());
};

export {
  authorize,
  login,
  getCustomer,
  getCustomerOrders,
  getCustomerOrder,
  deactivate,
  getCustomerProductKeys,
  getCustomerOrdersById,
  getCustomerById,
  listCustomers,
  findCustomer,
  deepSearchCustomer,
  lazySync,
  sendCustomerAccessLink,
  sendCustomerActivationLink,
  mergeCustomer,
  unmergeCustomer,
  syncSendOwl,
  syncLimeLM,
  revokeLimeLMKey,
  unrevokeLimeLMKey,
  adminDeactivate,
  health
};
