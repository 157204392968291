// jshint esversion: 6

import React from 'react';
import _ from 'lodash';
import { adminDeactivate } from '../api';
import macbook_pro from '../images/macbook_pro.svg';
import macbook_air from '../images/macbook_air.svg';
import macbook from '../images/macbook.svg';
import mac_mini from '../images/mac_mini.svg';
import mac_pro from '../images/mac_pro.svg';
import imac from '../images/imac.svg';

const imageMap = {
  "MacBookPro": macbook_pro,
  "MacBookAir": macbook_air,
  "MacBook": macbook,
  "iMac": imac,
  "MacMini": mac_mini,
  "MacPro": mac_pro
};

const Activations = ({ productId, details, setError, admin, refetchCustomer }) => {
  const handleDeactivate = (productId, activationId, productKey) => {
    return adminDeactivate({ jwt: admin.JWT, productId, productKey, activationId })
      .then(resp => {
        if (resp.Error || resp.message) {
          setError(resp.Error ? resp.Error : resp.message);
          alert("Deactivation failed.\n\nPlease contact support@cinemagrade.com");
          return;
        }
        refetchCustomer();
      });
  };

  if (!details?.activations?.act) return null;
  return details.activations.act.map(activation => {
    const img = _.find(imageMap, (image, key) =>  _.includes(_.get(activation, 'extra_data', '').split(',')[1], key));
    return  <tr className='activation'>
      <td className='activationDetails'>
        <span>{activation.extra_data}</span>
      </td>
      <td>
        { img ? <img src={img} className="act_type" alt={img} />  : <span /> }
      </td>
      <td><button onClick={() => handleDeactivate(productId, activation.id, details.key)}>Deactivate</button></td>
    </tr>
  })
};

export default Activations
