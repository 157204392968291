// jshint esversion: 6

import React from 'react';

const Orders = ({ orders }) => {
  const renderOrders = () => {
    if (!orders) return null;
    return orders.map(order => {
      const details = order.Details ? JSON.parse(order.Details) : {};
      return <tr key={order.OrderID}>
        <td><a href={ "https://www.sendowl.com/manage_orders/" + order.OrderID + "/" } target="_blank" >{order.OrderID}</a></td>
        <td>${details.settled_gross || '0.00'}</td>
        <td>{details.created_at}</td>
      </tr>
    });
  };

  return <table>
    <thead>
      <tr>
        <th><h3>OrderID</h3></th>
        <th><h3>Settled Gross</h3></th>
        <th><h3>Created At</h3></th>
      </tr>
    </thead>
    <tbody>
      {renderOrders()}
    </tbody>
  </table>
};

export default Orders;
