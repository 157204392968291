// jshint esversion: 6

import React from 'react';
import _ from 'lodash';
import { revokeLimeLMKey, unrevokeLimeLMKey } from '../../api';
import Activations from '../Activations';

const ProductKeys = ({ productKeys, admin, setError, fetchProductKeys, handleSendCustomerActivationLink, refetchCustomer }) => {
  const [activationMode, setActivationMode] = React.useState();
  const handleClick = (productId, productKey, unrevoke) => {
    if (!window.confirm('Are you sure?')) return;
    const revokeFunc = unrevoke ? unrevokeLimeLMKey : revokeLimeLMKey;
    return revokeFunc({ jwt: admin.JWT, productId, productKey })
      .then(resp => {
        if (resp?.message) setError(resp.message);
        fetchProductKeys();
      });
  };

  const renderKeys = () => {
    if (!productKeys) return null;
    return productKeys.map(key => {
      const details = key.Details ? JSON.parse(key.Details) : {};
      const info = key.ProductInfo;
      const orderId = _.get(_.find(details.features.feature, feature => feature.name === 'order_id'), 'value', '');
      const rows =[<tr key={key.ProductKey}>
      <td>
        <div className="productDesc">{info.name} {info.version}</div>
        <a className={details.revoked ? 'revoked' : ''} href={ "https://wyday.com/limelm/pkey/" + key.LimeLMID } target="_blank" rel='noreferrer'>{key.ProductKey}</a>
        </td>
        <td>
          { details.revoked ? <button className='unrevoke' onClick={() => handleClick(info.id, key.ProductKey, true)}>Unrevoke</button>: <button className='revoke' onClick={() => handleClick(key.ProductKey, false)}>Revoke</button> }
        </td>
        <td>
          <button className='activations' disabled={details.acts_used > 0 ? null : 'disabled'} onClick={() => setActivationMode(activationMode === key.ProductKey ? false : key.ProductKey)}>{activationMode === key.ProductKey ? 'Cancel' : 'Deactivate'}</button>
        </td>
        <td>
          <button onClick={() => handleSendCustomerActivationLink({ activations: details.acts, orderId, productKey: details.key, productId: info.id})} name='activationLink'>Email Product Key</button>
        </td>
      </tr>];
      if (activationMode === key.ProductKey) {
        rows.push(<Activations productId={info.id} details={details} setError={setError} refetchCustomer={() => { setActivationMode(false); refetchCustomer() }} admin={admin} />)
      }
      return rows;
    });
  };

  return <table>
    <thead>
      <tr>
        <th colSpan='4'><h3>Keys</h3></th>
      </tr>
    </thead>
    <tbody>
      {renderKeys()}
    </tbody>
  </table>
};

export default ProductKeys;
