// jshint esversion: 6

import React from 'react';
import { Link } from 'react-router-dom';
import { FaEnvelope } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import PaginationControls from '../PaginationControls';
import Error from '../Error';
import { sendCustomerAccessLink } from '../../api';
import '../../css/admin.css';

const Customers = ({ customers, callback, refreshPage, handleSort, sort }) => {
  const [error, setError] = React.useState();
  const [status, setStatus] = React.useState();
  const [admin] = React.useState(JSON.parse(localStorage.getItem('admin')));

  const renderCustomers = () => {
    if (!customers.customers) return null;
    const direction = sort.direction === 'asc' ? 'desc' : 'asc';

    const handleSendCustomerAccessLink = (customer) => {
      sendCustomerAccessLink({ jwt: admin.JWT, customer })
        .then(resp => {
          if (resp !== null) {
            setError(resp);
            return;
          }
          setStatus(`Access Link sent to ${customer.Email}`);
        });
    };

    return <table className='customers'>
      <thead>
        <tr>
          <th onClick={() => handleSort({ field: 'email', direction })} className={sort.field === 'email' ? 'sortable bold' : 'sortable'}>
            Email
            { sort.field ==='email' ?  sort.direction === 'asc' ? <i className="arrow up" /> : <i className="arrow down" /> : null }
          </th>
          <th onClick={() => handleSort({ field: 'additional_emails', direction })} className={sort.field === 'additional_emails' ? 'sortable bold' : 'sortable'}>
            Additional Emails
            { sort.field ==='additional_emails' ?  sort.direction === 'asc' ? <i className="arrow up" /> : <i className="arrow down" /> : null }
          </th>
          <th onClick={() => handleSort({ field: 'last_login', direction })} className={sort.field === 'last_login' ? 'sortable bold' : 'sortable'}>
            Last Login
            { sort.field ==='last_login' ?  sort.direction === 'asc' ? <i className="arrow up" /> : <i className="arrow down" /> : null }
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        {customers.customers.map(customer => <tr key={`customer-${customer.CustomerID}`}>
          <td><Link to={`/admin/customer?customerId=${customer.CustomerID}&customerEmail=${customer.Email}`}>{customer.Email}</Link></td>
          <td>{customer.AdditionalEmails}</td>
          <td>{customer.LastLogin}</td>
          <td>
            <FaEnvelope className='hover' data-tip='Send Access Link' onClick={() => handleSendCustomerAccessLink(customer)}/>
            <ReactTooltip/>
          </td>
        </tr>)}
      </tbody>
    </table>;
  };

  if (error) return <Error error={error} />

  return <div className='customers'>
    { status ? <div className='status'>{status}</div> : null }
    {renderCustomers()}
    {customers.total ? <PaginationControls items={customers.customers} totalPages={customers.total} callback={callback} refreshPage={refreshPage}  /> : null}
  </div>;
};

export default Customers;
