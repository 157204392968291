// jshint esversion: 6

import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import Login from './components/user/Login';
import License from './components/user/License';
import Invoices from './components/user/Invoices';
import Invoice from './components/user/Invoice';
import User from './components/user/User';
import Customer from './components/admin/Customer';
import Header from './components/admin/Header';
import ListCustomers from './components/admin/ListCustomers';
import FindCustomers from './components/admin/FindCustomers';
import { clientID } from './config';

import { GoogleOAuthProvider } from '@react-oauth/google';

function App() {
  return (
    <div className='App'>
      <GoogleOAuthProvider clientId={clientID}>
        <div className='main'>
          <Router>
            <Switch>
              <Route path='/admin/customer' children={<Header child={<Customer />} />}></Route>
              <Route path='/admin/customers' children={<Header child={<ListCustomers />} />}></Route>
              <Route path='/admin/search' children={<Header child={<FindCustomers searchType='shallow' />} />}></Route>
              <Route path='/admin/deepsearch' children={<Header child={<FindCustomers searchType={'deep'} />} />}></Route>
              <Route path='/admin'><Redirect to='/admin/customers' /></Route>
              <Route path='/license/:token' children={<User child={<License />} />}></Route>
              <Route path='/invoices/:token' children={<User child={<Invoices />} />}></Route>
              <Route path='/invoice/:id/:token' children={<User child={<Invoice />} />}></Route>
              <Route path='/'>
                <User child={<Login />} />
              </Route>
            </Switch>
          </Router>
        </div>
        <div className='footer'>
            <a href='https://www2.cinemagrade.com/' rel='noreferrer' target='_blank'>© 2021-2022 Color Grading LLC</a>
        </div>
      </GoogleOAuthProvider>;
    </div>
  );
}

export default App;
